.header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: #eee;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    @media (max-width: 767px) {
      padding: 15px 0;
    }

    @media screen and (max-width: 399px) {
      gap: 15px;
    }
  }

  .toolbar {
    display: flex;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 475px) {
      gap: 15px;
    }

    @media screen and (max-width: 399px) {
      width: 100%;
    }
  }

  .toggleMenu {
    font-size: 3.2rem;
    color: var(--default-purple);
    line-height: 0;
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }
}
