.nav {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    font-family: var(--headingFont);
    font-weight: 700;
  }

  .navHeaderMobile {
    display: none;
  }

  .socialMobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .nav {
    position: fixed;
    top: 0;
    right: -87%;
    bottom: 0;
    width: 85%;
    background: #fff;
    padding: 15px;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    box-shadow: -4px 0px 8px 2px rgba(0, 0, 0, 0.25);

    .backdrop {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      background: rgb(0 0 0 / 50%);
      z-index: -1;
      width: 15%;
    }

    &.open {
      right: 0;
    }

    .navHeaderMobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid var(--default-purple);

      svg {
        color: var(--default-purple);
        font-size: 3.2rem;
      }
    }

    ul {
      flex-direction: column;
      gap: 0;

      a {
        line-height: 4.8rem;
      }
    }

    .socialMobile {
      display: block;
      text-align: center;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid var(--default-purple);
      line-height: 0;
    }
  }
}

@media (max-width: 575px) {
  .socialMobile a {
    font-size: 2.6rem;
  }
}
