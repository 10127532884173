.form {
  max-width: 570px;
  width: 100%;
  margin: 0 auto;

  .inputGroup {
    margin-bottom: 1.5rem;
    position: relative;
  }
  .inputGroup input,
  .inputGroup textarea {
    width: 100%;
    border: 0;
    outline: none;
    background: #eeeeee;
    border-radius: 5px;
    padding: 1.5rem;
    color: #000;
  }
  .inputGroup textarea {
    min-height: 200px;
    height: 100%;
    resize: none;
  }

  .inputError {
    font-size: 1rem;
    color: red;
    position: absolute;
    bottom: 0;
    left: 15px;
  }

  .submit {
    padding: 1.5rem 3rem;
    background: var(--default-purple);
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: var(--headingFont);
    border-radius: 5px;
    transition: all .3s ease-in-out;
  }
  .submit:hover {
    filter: brightness(1.1);
  }

  .formControl {
    position: relative;

    section {
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .error,
  .success {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1.4rem;
  }
  .success {
    color: green;
  }
  .error {
    color: red;
  }
}