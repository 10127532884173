.contactWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding-top: 3rem;

  .contactLeft {
    max-width: 490px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    max-width: 570px;
    width: 100%;
    margin: 0 auto;

    .contactLeft {
      display: flex;
      flex-direction: column;

      article:first-of-type {
        order: 1;
      }
      article:last-of-type {
        order: 2;
      }
      article:nth-of-type(2) {
        order: 3;
      }
    }
  }

  h1 {
    font-size: 2.4rem;
  }

  h1, h2 {
    margin-bottom: 0.5rem;;
  }

  section + section {
    margin: 0;
  }

  article + article {
    margin-top: 3rem;
  }

  article {
    div {
      text-align: left;
    }
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }
}