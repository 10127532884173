.socialWrapper {
  padding: 10px 0;
  text-align: center;
  line-height: 0;
}

.socialLink {
  font-size: 1.8rem;
  display: inline-block;
  line-height: 0;

  @media(max-width: 575px) {
    font-size: 2.6rem;
  }

  & + .socialLink {
    margin-left: 20px;

    @media(max-width: 575px) {
      margin-left: 26px;
    }
  }
}