.main {
  min-height: calc(100vh - 100px - 60px - 199px);
  margin: 3rem auto;
  position: relative;

  section + section {
    margin-top: 6rem;

    @media (max-width: 575px) {
      margin-top: 3rem;
    }
  }
}
