.formWrapper {
  background: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;

  @media screen and (max-width: 399px) {
    width: 100%;
  }

  input {
    border: 0;
    outline: none;
    padding: 11px;
    line-height: 1.8rem;

    @media screen and (max-width: 475px) {
      width: 100%;
      max-width: 140px;
    }

    @media screen and (max-width: 399px) {
      max-width: 100%;
      padding: 7px 13px;
    }
  }

  button {
    line-height: 0;
    padding: 13px;
    font-size: 1.4rem;

    @media screen and (max-width: 399px) {
      padding: 9px 13px;
    }
  }
}
